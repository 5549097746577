<template>
  <div class="UserAvatar">
    <figure :class="`user-avatar ${user.picture ? 'picture' : ''}`" v-if="user" :style="style" :type="type" @click="$emit('click')">
      <span class="overblur" centered v-if="user.picture"></span>
      <img class="media" v-if="user.picture" :src="user.picture" />
      <span class="text" uppercase v-if="!user.picture">{{ user.name && user.name[0] }}</span>
      <!-- <i class="flag" :style="`--flag:url(${user.country_flag})`"></i> -->
    </figure>
    <span class="media-pending" v-if="!isClean && isPendingMedia" @click.prevent="viewPendingMedia">
      <iconic name="triangle_exclamation" />
    </span>
    <span :class="`userOnlineStatus ${user.online ? '__online' : '__ofline'}`" v-if="!isClean && showOnlineStatus"></span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["user", "size", "type", "clean"],
  methods: {
    viewPendingMedia: function() {
      this.$store.state.user.profileReview = true;
      this.$emit("action");
    },
  },
  computed: {
    pictureStyle: function() {
      return (this.user.style && this.user.style.picture) || {};
    },
    moved: function() {
      let [x, y, cWidth, cHeight] = [this.pictureStyle.x, this.pictureStyle.y, 200, 200];

      x = (x * 100) / cWidth;
      y = (y * 100) / cHeight;

      return { x, y };
    },
    style: function(style = "") {
      style = `--picture:url(${this.user.picture});`;
      style += `--size:${this.size || "49px"};`;
      style += `--zoom:${Go.onlyNum(this.pictureStyle.zoom) || "100"}%;`;
      style += `--x:${this.moved.x || "0"}%;`;
      style += `--y:${this.moved.y || "0"}%;`;
      return style;
    },
    isClean: function() {
      return this.clean === true || this.clean === "true";
    },
    isPendingMedia: function() {
      if (!this.$profile?.user?.payload) {
        return false;
      }

      const isMe = this.isMyAvatar;
      const pendingPic = this.$profile.user.payload.picture;
      const pendingCov = this.$profile.user.payload.cover;

      if (isMe && pendingPic && pendingPic.adminStatus !== "accept") {
        return true;
      }

      if (isMe && pendingCov && pendingCov.adminStatus !== "accept") {
        return true;
      }
    },
    isMyAvatar: function() {
      return this.$profile && this.$profile.user.id === this.user.id;
    },
    showOnlineStatus: function() {
      if (this.$isAdmin) {
        return true;
      }

      if (this.$isCreator) {
        return true;
      }

      if (this.isCreator(this.user)) {
        return true;
      }

      if (this.isAdminCreator()) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss">
.UserAvatar {
  border-radius: 50%;
  position: relative;
  display: inline-block;
  margin: auto;

  .camera-figure {
    position: absolute;
    @include centered();
    cursor: pointer;
  }

  .user-avatar {
    position: relative;
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
    border-radius: 50%;
    color: #fff;
    @include Flex(inherit, center, center);
    font-family: $third_font_bold;
    overflow: hidden;

    .overblur {
      background-image: var(--picture);
      background-position: center;
      filter: blur(20px);
      background-size: 200%;
      z-index: 0;
      width: 150%;
      height: 150%;
      position: absolute;
    }

    &:not(.picture) {
      background-color: $primary_color;
    }

    .media {
      position: relative;
      z-index: 1;
      transform: translate(var(--x), var(--y));
      width: calc(var(--zoom));
      height: auto;
      inset: 0px;
      max-width: 200%;
    }

    .media-pending {
      position: absolute;
      top: -$mpadding/2;
      right: -$mpadding/1.5;
      width: $mpadding * 2;
      height: $mpadding * 2;
      background: red;
      color: rgb(255, 255, 255);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: solid #fff;
      cursor: pointer;
      overflow: hidden;
      z-index: 1;
    }

    &[type="story"] {
      &:after,
      &:before {
        content: "";
        position: absolute;
        width: 54px;
        height: 54px;
        border-radius: 50%;
        border: solid $primary_color 2px;
        border-style: dashed;
      }
      &:before {
        border-style: solid;
        border-color: #fff;
      }
    }

    &[border="true"] {
      border: solid $primary_color;
    }
  }
}
</style>
